<template>
  <section class="pay_page">
    <NavBar />
    <Header
      type="payPage"
    />
    <div class="content box">
      <div class="pay_success mt50 p0240" v-if="payStatus">
        <div class="body frfsfs p300">
          <div class="left mr30">
            <img src="~assets/images/pay-success.png">
          </div>
          <div class="right">
            <div class="status mt15">您已付款成功！</div>
            <div class="describe mt30">
              请在个人中心-我的订单中查看订单状态。<br>如遇问题请拨打青葫芦童书直卖店客服电话：400-532-4821<br>服务时间：周一至周六 9:00-18:00
            </div>
          </div>
        </div>
        <div class="footer mt50 f">
          <div class="return mr30" @click="returnPurchaseOrder">返回采购单</div>
          <div class="see_order" @click="seeOrder">查看订单</div>
        </div>
      </div>
      <div v-else>
        <div class="checkstand mt50">
          <div class="header">收银台</div>
          <div class="body mt30 fccfs p30">
            <p>订单编号：{{datas.orderNo}}</p>
            <p class="m300">订单时间：{{datas.orderTime}}</p>
            <p>订单金额：<span>{{datas.orderAmount}}元</span></p>
          </div>
        </div>
        <div class="pay_time mt30 p30">
          <div class="icon">
            <img src="~assets/images/warning.png">
          </div>
          <div class="text">
            请您在 <span>{{payCountdown}}</span> 内完成付款，以便我们及时为您确认配送，您可以任意选择以下付款方式（二选一）进行支付，请勿重复付款。
          </div>
        </div>
        <div class="code_pay mt30">
          <div class="wx_pay">
            <div class="header">
              微信扫码支付
            </div>
            <div class="body p30">
              <img :src="datas.codeImgUrl">
            </div>
          </div>
          <div class="yh_pay">
            <div class="header">
              银行转账
            </div>
            <div class="body p30">
              <p class="prompt">您可通过银行转账汇款的方式进行付款。<br>在转账前，请务必联系我们的销售顾问，以便及时确认到账情况。</p>
              <div class="content mt20 p30">
                <p class="title">账户信息</p>
                <p class="name mt10">收款人全称：福州葫芦弟弟科技有限公司</p>
                <p class="account">收款账号：35050187530000000787</p>
                <p class="where">开户行：中国建设银行股份有限公司福州金山大道支行</p>
                <p class="warning mt10">注：如转账所需时间较长，请及时联系销售顾问为您延长订单支付时间。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import {Loading} from 'element-ui';
  import QRCode from 'qrcode';
  import NavBar from 'components/NavBar/index.vue';
  import Header from 'components/Header/index.vue';
  import {
    getData,
    result,
    goPayGetData,
    whetherPay
  } from 'api/payPage.js';

  export default {
    components: {
      NavBar,
      Header
    },

    created() {
      if(this.$route.params.addressId || this.$route.params.orderNo) {
        if(this.$route.params.orderNo) {
          this.goPayGetData(this.$route.params.orderNo);
        }else {
          this.loading = Loading.service({
            lock: true,
            text: '订单生成中，请稍后...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.addressId = this.$route.params.addressId;
          this.getData();
        }
      }else {
        this.$router.push({
          name: 'settlementPage'
        });
      }
    },

    mounted() {
      setInterval(() => {
        this.time -= 1000;
      }, 1000);
    },

    destroyed() {
      clearInterval(this.timer);
    },

    data() {
      return {
        addressId: '',
        datas: {},
        loading: null,
        timer1: null,
        timer: null,
        payStatus: false,
        time: 0
      }
    },

    computed: {
      payCountdown() {
        let hours = Math.floor(this.time / 3600000)
        let minutes = Math.floor(this.time % 3600000 / 60000);
        let seconds = Math.floor(this.time % 3600000 % 60000 / 1000);
        return `${hours}时${minutes}分${seconds}秒`;
      }
    },

    methods: {
      getData() {
        getData({
          addressId: this.addressId
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.timer1 = setInterval(() => {
            result({
              key: res.data
            }).then(r => {
              if(r.code == 1) {
                this.$message.error(res.message);
                this.loading.close();
                clearInterval(this.timer1);
                return;
              }
              if(r.code == 0) {
                this.$message.success(res.message);
                this.loading.close();
                clearInterval(this.timer1);
                this.datas = r.data;
                this.time = Math.floor(this.datas.implementCloseTime - new Date().getTime());
                QRCode.toDataURL(
                  this.datas.codeUrl,
                  {
                    errorCorrectionLevel: 'L',
                    margin: 2,
                    width: 128
                  },
                  (err, url) => {
                    if(err) {
                      this.$message.error(err);
                      return;
                    }
                    this.datas.codeImgUrl = url;
                  }
                );
                this.timer = setInterval(() => {
                  this.whetherPay();
                }, 1000);
              }
            });
          }, 1000);
        });
      },
      goPayGetData(orderNo) {
        goPayGetData(orderNo).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.datas = res.data;
          this.time = Math.floor(this.datas.implementCloseTime - new Date().getTime());
          QRCode.toDataURL(
            this.datas.codeUrl,
            {
              errorCorrectionLevel: 'L',
              margin: 2,
              width: 128
            },
            (err, url) => {
              if(err) {
                this.$message.error(err);
                return;
              }
              this.datas.codeImgUrl = url;
            }
          );
          this.timer = setInterval(() => {
            this.whetherPay();
          }, 1000);
        });
      },
      whetherPay() {
        whetherPay({
          orderNo: this.datas.orderNo
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          if(res.data.payStatus == 2) {
            clearInterval(this.timer);
            this.payStatus = true;
          }
        });
      },
      returnPurchaseOrder() {
        this.$router.push({
          name: 'purchaseOrder'
        });
      },
      seeOrder() {
        this.$router.push({
          name: 'personalCenter'
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .pay_page {
    .content {
      margin-bottom: 30px;
      .pay_success {
        width: 100%;
        height: 320px;
        background: var(--colorfff);
        border: 1px solid var(--colorccc);
        .body {
          border-bottom: 1px solid var(--colore6e6e6);
          .left {
            width: 80px;
            height: 80px;
          }
          .right {
            .status {
              font-size: var(--fontSize24);
              color: var(--color000);
              font-weight: bold;
            }
            .describe {
              font-size: var(--fontSize12);
              color: var(--color666);
              font-weight: 400;
              line-height: 16px;
            }
          }
        }
        .footer {
          .return {
            width: 120px;
            height: 40px;
            background: var(--colorfff);
            border: 1px solid var(--colorccc);
            border-radius: 8px;
            font-size: var(--fontSize16);
            color: var(--color666);
            font-weight: 500;
            text-align: center;
            line-height: 38px;
            cursor: pointer;
          }
          .see_order {
            width: 120px;
            height: 40px;
            background: var(--colored6d00);
            border-radius: 8px;
            font-size: var(--fontSize16);
            color: var(--colorfff);
            font-weight: bold;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
          }
        }
      }
      .checkstand {
        .header {
          font-size: var(--fontSize16);
          color: var(--color000);
          font-weight: bold;
        }
        .body {
          background: var(--colorfbfbfb);
          border: 1px solid var(--colorccc);
          p {
            font-size: var(--fontSize14);
            color: var(--color000);
            font-weight: 500;
            span {
              color: var(--colorfe1818);
            }
          }
        }
      }
      .pay_time {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 90px;
        background: #fffaec;
        .icon {
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }
        .text {
          font-size: 14px;
          color: #ed6c00;
          span {
            color: #fe1818;
            font-weight: bold;
          }
        }
      }
      .code_pay {
        display: flex;
        justify-content: space-between;
        .wx_pay {
          margin-right: 30px;
          width: 30%;
          background: var(--colorf7f7f7);
          border: 1px solid var(--colorccc);
          .header {
            height: 50px;
            background: var(--colorfff);
            font-size: var(--fontSize16);
            color: var(--color000);
            font-weight: 500;
            text-align: center;
            line-height: 50px;
          }
          .body {
            margin: 0 auto;
            width: 300px;
            height: 300px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .yh_pay {
          flex: 1;
          background: var(--colorf7f7f7);
          border: 1px solid var(--colorccc);
          .header {
            height: 50px;
            background: var(--colorfff);
            font-size: var(--fontSize16);
            color: var(--color000);
            font-weight: 500;
            text-align: center;
            line-height: 50px;
          }
          .body {
            .prompt {
              font-size: 12px;
              color: #000;
              line-height: 20px;
            }
            .content {
              width: 100%;
              background: #fff;
              border: 1px solid #e9e9e9;
              .title {
                font-size: 16px;
                color: #000;
                font-weight: bold;
              }
              .name, .account, .where {
                font-size: 14px;
                color: #000;
                line-height: 20px;
              }
              .warning {
                font-size: 12px;
                color: #999;
              }
            }
          }
        }
      }
    }
  }
</style>
