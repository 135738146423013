import request from 'network/request';

const baseURL = '/order/extend/';

export function getData(params) {
  return request({
    url: baseURL + 'po/confirm',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function result(params) {
  return request({
    url: baseURL + 'po/confirm/result',
    method: 'get',
    params
  });
}
export function goPayGetData(params) {
  return request({
    url: baseURL + 'po/repay/' + params,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function whetherPay(params) {
  return request({
    url: baseURL + 'payment',
    method: 'get',
    params
  });
}
